import React, { useState } from "react"
import { Text } from "../Core"

import "./ProgramToast.scss"

const ProgramToast = ({ closeDate, children }) => {
  if (!children) return null

  const [close, setClose] = useState(false)

  const today = new Date()
  const end = new Date(closeDate)

  if (close || today > end) return null

  return (
    <div className="program-toast">
      <span className="close-toast" onClick={() => setClose(true)}>
        ✖
      </span>
      <Text text={children} />
    </div>
  )
}

export default ProgramToast
